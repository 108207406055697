import { useEffect } from 'react'
import useSWR from 'swr'

import type { RootState } from '@/redux/store'
import { actions as uiActions } from '@/redux/ui/slice'
import { getBanks } from '@/services/bank'
import { getLoans } from '@/services/loan'
import { getLoanVariants } from '@/services/loanVariant'
import { getRates } from '@/services/rate'
import { useAppDispatch, useAppSelector } from './index'

export function useBanks () {
  return useAPI('/banks', state => state.banks.revalidate, getBanks)
}

export function useLoans (bankId:string) {
  return useAPI(['/loans', bankId], state => state.loans.revalidate, getLoans, bankId)
}

export function useLoanVariants (loanId:string) {
  return useAPI(['/variants', loanId], state => state.loanVariants.revalidate, getLoanVariants, loanId)
}

export function useRates () {
  return useAPI('/rates', state => state.rates.revalidate, getRates)
}

function useAPI<T> (
  key:string | string[],
  revalidateSelector:(state:RootState)=>boolean,
  service:(...arg:string[])=>Promise<T>,
  ...args:string[]
) {
  const dispatch = useAppDispatch()
  const swr = useSWR(key, () => service(...args))
  const revalidate = useAppSelector(revalidateSelector)

  useEffect(() => {
    if (!swr.data && !swr.error) {
      dispatch(uiActions.UI_LOADING_SET(true))
    } else {
      dispatch(uiActions.UI_LOADING_SET(false))
    }
  }, [swr.data, swr.error])

  useEffect(() => {
    if (revalidate) swr.mutate()
  }, [revalidate])

  return swr
}
