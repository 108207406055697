import '@aws-amplify/ui-react/styles.css'

import { Authenticator } from '@aws-amplify/ui-react'

import { MsiLayout } from '@/components/MsiLayout'
import { AuthComponents } from '@/utils/auth'

function App () {
  return (
    <Authenticator components={AuthComponents} hideSignUp>
      {() => (
        <MsiLayout />
      )}
    </Authenticator>
  )
}

export default App
