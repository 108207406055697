import type { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import type { GridColumns } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'

import { MsiImgFallback } from '@/components/MsiImgFallback'
import { useAppDispatch } from '@/hooks'
import { actions } from '@/redux/modal/slice'
import type { Bank } from '@/types/bank'

type Props = PropsWithChildren<{
  data: Bank[]
}>

interface ColumnProps {
  onEdit: (item:Bank)=>void
  onDelete: (item:Bank)=>void
}
const assetsPath = 'https://assets.mutuisi-calculator.mikamai.us/public/logos/'

const columnFactory = ({ onEdit, onDelete }:ColumnProps) => [
  {
    field: 'bank_name',
    headerName: 'nome banca',
    flex: 1
  },
  {
    field: 'id',
    headerName: 'logo',
    flex: 1,
    renderCell (params) {
      return (
        <>
          <MsiImgFallback
            key={Math.random().toString(32)}
            src={`${assetsPath}${params.row.id}`}
            style={{ height: '2em', width: 'auto' }}
          ></MsiImgFallback>
        </>
      )
    }
  },
  {
    field: 'actions',
    headerName: 'actions',
    width: 190,
    renderCell: params => {
      return (
        <Box display="flex" gap={2}>
          <Button color="primary" variant="outlined" onClick={() => onEdit(params.row)}>edit</Button>
          <Button color="warning" variant="outlined" onClick={() => onDelete(params.row)}>delete</Button>
        </Box>
      )
    }
  },
  {
    field: 'view',
    headerName: 'view',
    width: 150,
    renderCell: params => {
      return (
        <Box display="flex" gap={2}>
          <Link to={`${params.row.id}/loans` }>
            <Button color="secondary" variant="outlined">view loans</Button>
          </Link>
        </Box>
      )
    }
  }
] as GridColumns<Bank>

const PAGESIZE = 100

export function MsiBanksTable ({ data }:Props) {
  const dispatch = useAppDispatch()

  function onEdit (item:Bank) {
    dispatch(actions.MODAL_ITEM_SET({
      item,
      type: 'bank',
      action: 'edit'
    }))
  }

  function onDelete (item:Bank) {
    dispatch(actions.MODAL_ITEM_SET({
      item,
      type: 'bank',
      action: 'delete'
    }))
  }

  return (
    <DataGrid
      rows={data}
      columns={columnFactory({ onEdit, onDelete })}
      hideFooter={data.length <= PAGESIZE}
      rowsPerPageOptions={[PAGESIZE]}
      pageSize={PAGESIZE}
      disableSelectionOnClick={true}
      disableColumnMenu={true}
      initialState={{ sorting: { sortModel: [{ field: 'bank_name', sort: 'asc' }] } }}
    />
  )
}
