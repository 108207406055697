import { MsiFormBank } from '@/components/forms/bank/MsiFormBank'
import { MsiFormLoan } from '@/components/forms/loan/MsiFormLoan'
import { MsiFormLoanVariant } from '@/components/forms/loanVariant/MsiFormLoanVariant'
import { MsiFormRate } from '@/components/forms/rate/MsiFormRate'
import { useAppSelector } from '@/hooks'
import { MsiModalDelete } from './MsiModalDelete'

export function MsiModals () {
  const modal = useAppSelector(state => state.modal)

  if (['create', 'edit'].includes(modal.action)) {
    if (modal.type === 'bank') return <MsiFormBank />
    if (modal.type === 'loan') return <MsiFormLoan />
    if (modal.type === 'loanVariant') return <MsiFormLoanVariant />
    if (modal.type === 'rate') return <MsiFormRate />
  }

  if (modal.action === 'delete') return <MsiModalDelete />

  return <></>
}
