import { Dispatch } from 'react'
import { Box } from '@mui/material'

import { loanTypeOptions, rateTypeOptions } from '@/components/forms/consts'
import type { LoanOverviewFilters } from '@/types'
import { MsiSearchInput } from './MsiSearchInput'
import { MsiSelect } from './MsiSelect'

interface Props {
  onChange: Dispatch<LoanOverviewFilters>
}

const filters:LoanOverviewFilters = {
  product_alias: '',
  loan_type: '' as LoanOverviewFilters['loan_type'],
  rate_type: '' as LoanOverviewFilters['rate_type']
}

export function MsiLoanSearch ({ onChange }:Props) {
  function onProductNameChange (value:string) {
    filters.product_alias = value
    onChange({ ...filters })
  }
  function onLoanTypeChange (value:string) {
    filters.loan_type = value as LoanOverviewFilters['loan_type']
    onChange({ ...filters })
  }
  function onRateTypeChange (value:string) {
    filters.rate_type = value as LoanOverviewFilters['rate_type']
    onChange({ ...filters })
  }

  return (
    <Box sx={{ padding: '1.5em 0', display: 'flex', gap: '20px', justifyContent: 'center' }}>
      <MsiSearchInput
        placeHolder='nome prodotto interno'
        onChange={onProductNameChange}
      />
      <MsiSelect
        options={loanTypeOptions}
        onChange={onLoanTypeChange}
        label='Tipo Mutuo'
        sx={{ width: '350px' }}
      />
      <MsiSelect
        options={rateTypeOptions}
        onChange={onRateTypeChange}
        label='Tipo Tasso'
        sx={{ width: '350px' }}
      />
    </Box>
  )
}
