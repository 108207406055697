import 'react-toastify/dist/ReactToastify.css'

import { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '@/hooks'
import { actions as bankActions } from '@/redux/banks/slice'
import { actions as loanActions } from '@/redux/loans/slice'
import { actions as loanVariantActions } from '@/redux/loanVariants/slice'
import { errorSelector, successSelector } from '@/redux/rootSelectors'
import { actions as uiActions } from '@/redux/ui/slice'

export function MsiToast () {
  const success = useAppSelector(successSelector)
  const error = useAppSelector(errorSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (success) {
      toast.success('success')

      dispatch(bankActions.RESET())
      dispatch(loanActions.RESET())
      dispatch(loanVariantActions.RESET())
      dispatch(uiActions.RESET())
    }
  }, [success])

  useEffect(() => {
    if (error) {
      toast.error(error)

      dispatch(bankActions.RESET())
      dispatch(loanActions.RESET())
      dispatch(loanVariantActions.RESET())
      dispatch(uiActions.RESET())
    }
  }, [error])

  return (
    <ToastContainer
      position="top-right"
      autoClose={1000}
      closeOnClick
      pauseOnHover
      theme='dark'
    />
  )
}
