/* eslint-disable @typescript-eslint/ban-ts-comment */

import { all, call, put, takeLatest } from 'redux-saga/effects'

import { loanVariantItemsCloneRequest } from '@/redux/loanVariants/sagas'
import { actions as loanVariantsActions } from '@/redux/loanVariants/slice'
import * as services from '@/services/loan'
import { UnwrapReturn } from '@/types'
import { loanModelToRequestBody } from '@/utils'
import { actions } from './slice'

function* loanItemCreateRequest ({ payload }:ReturnType<typeof actions.LOAN_ITEM_CREATE_REQUEST>) {
  try {
    yield call(services.createLoan, payload.data)
    yield put(actions.LOAN_ITEM_CREATE_SUCCESS())
  } catch (error) {
    yield put(actions.LOAN_ITEM_CREATE_FAILURE((error as Error).message))
  }
}
function* loanItemUpdateRequest ({ payload }:ReturnType<typeof actions.LOAN_ITEM_UPDATE_REQUEST>) {
  try {
    // @ts-ignore
    yield call(services.updateLoan, payload.id, payload.data)
    yield put(actions.LOAN_ITEM_UPDATE_SUCCESS())
  } catch (error) {
    yield put(actions.LOAN_ITEM_UPDATE_FAILURE((error as Error).message))
  }
}
function* loanItemDeleteRequest ({ payload }:ReturnType<typeof actions.LOAN_ITEM_DELETE_REQUEST>) {
  try {
    yield call(services.deleteLoan, payload.id)
    yield put(actions.LOAN_ITEM_DELETE_SUCCESS())
  } catch (error) {
    yield put(actions.LOAN_ITEM_DELETE_FAILURE((error as Error).message))
  }
}

function* loanItemCloneRequest ({ payload }:ReturnType<typeof actions.LOAN_ITEM_CLONE_REQUEST>) {
  try {
    const { id, ...data } = loanModelToRequestBody(payload.loan)
    const loanId:UnwrapReturn<typeof services.createLoan> = yield call(services.createLoan, data)
    try {
      const action = loanVariantsActions.LOANVARIANT_ITEMS_CLONE_REQUEST(loanId, payload.loan.loan_variants)
      // @ts-ignore
      yield call(loanVariantItemsCloneRequest, action)
    } catch { }
    yield put(actions.LOAN_ITEM_CLONE_SUCCESS())
  } catch (error) {
    yield put(actions.LOAN_ITEM_CLONE_FAILURE((error as Error).message))
  }
}

export function* loansSagas () {
  yield all([
    takeLatest(actions.LOAN_ITEM_CREATE_REQUEST.type, loanItemCreateRequest),
    takeLatest(actions.LOAN_ITEM_UPDATE_REQUEST.type, loanItemUpdateRequest),
    takeLatest(actions.LOAN_ITEM_DELETE_REQUEST.type, loanItemDeleteRequest),
    takeLatest(actions.LOAN_ITEM_CLONE_REQUEST.type, loanItemCloneRequest)
  ])
}
