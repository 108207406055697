import { Box, Button } from '@mui/material'

import { MsiNavRates } from '@/components/MsiNavRates'
import { MsiRatesTable } from '@/components/tables/MsiRatesTable'
import { useAppDispatch, useRates } from '@/hooks'
import { actions as modalActions } from '@/redux/modal/slice'
import type { Rate } from '@/types'

export function MsiRates () {
  const dispatch = useAppDispatch()
  const { data } = useRates()

  if (!data) {
    return <></>
  }

  function createRate () {
    dispatch(modalActions.MODAL_ITEM_SET({
      action: 'create',
      type: 'rate',
      item: {} as Rate
    }))
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 2 }}>
        <Box flex={1}>
          <MsiNavRates />
        </Box>
        <div>All Rates</div>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={createRate}>
            Create Rate
          </Button>
        </Box>
      </Box>
      <MsiRatesTable data={data} />
    </>
  )
}
