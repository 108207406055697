import * as yup from 'yup'

import { rateTypeOptions } from '@/components/forms/consts'

const getValues = (arr:{value: string, label: string}[]) => arr.map(({ value }) => value)
const rateTypeValues = getValues(rateTypeOptions)

export const schema = yup.object().shape({
  rate_name: yup.string().required('required'),
  rate_value: yup.number().required('required'),
  rate_type: yup.string().oneOf(rateTypeValues, `one of: ${rateTypeValues.join(', ')}`)
})
