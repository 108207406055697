import { Dispatch, useCallback, useEffect, useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import { ReactComponent as Clear } from '@/assets/clear.svg'
import { ReactComponent as Search } from '@/assets/search.svg'
import { debounce } from '@/utils'

interface Props {
  onChange: Dispatch<string>
  placeHolder: string
}

export function MsiSearchInput ({ onChange, placeHolder }:Props) {
  const [text, setText] = useState('')
  const debounced = useCallback(
    debounce(text => onChange(text.trim()), 500)
    , []
  )

  useEffect(() => debounced.cancel, [])

  function handleIconClick () {
    if (!text) return
    setText('')
    onChange('')
  }

  return (
    <TextField
      label={placeHolder}
      sx={{ width: '350px' }}
      value={text}
      onChange={(e:React.ChangeEvent<HTMLInputElement>) => debounced(e.target.value)}
      onInput={(e:React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleIconClick}>
              {text
                ? <Clear style={{ height: '0.8em', width: 'auto' }} />
                : <Search style={{ height: '0.8em', width: 'auto' }} />
              }
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
