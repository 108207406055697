import { Route, Routes } from 'react-router-dom'

import { Msi404 } from '@/pages/Msi404'
import { MsiBanks } from '@/pages/MsiBanks'
import { MsiLoans } from '@/pages/MsiLoans'
import { MsiLoanVariants } from '@/pages/MsiLoanVariants'
import { MsiRates } from '@/pages/MsiRates'
import { MsiRedirect } from './MsiRedirect'

export function MsiRoutes () {
  return (

    <Routes>
      <Route path="/" element={<MsiRedirect to="/banks" />} />
      <Route path="banks" element={<MsiBanks />} />
      <Route path="banks/:bankId/loans" element={<MsiLoans />} />
      <Route path="banks/:bankId/loans/:loanId/variants" element={<MsiLoanVariants />} />
      <Route path="rates" element={<MsiRates />} />
      <Route path="*" element={<Msi404 />} />
    </Routes>
  )
}
