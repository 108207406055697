import { useTheme, View } from '@aws-amplify/ui-react'
import { Amplify, Auth, Hub } from 'aws-amplify'

import { ReactComponent as Logo } from '@/assets/logo.svg'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_AWS_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    mandatorySignIn: true
  },
  API: {
    endpoints: [{
      name: 'API',
      endpoint: process.env.REACT_APP_API_BASEURL,
      custom_header: async () => {
        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
      }
    }]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_ASSETS_BUCKET,
      region: process.env.REACT_APP_AUTH_AWS_REGION
      // userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
      // userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_CLIENT_ID,
      // identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID
    }
  }
})

export function logout () {
  Auth.signOut()
}

Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signOut':
      location.reload()
      break
    case 'tokenRefresh_failure':
      logout()
      break
  }
})

export const AuthComponents = {
  Header () {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.small}>
        <Logo style={{ width: 'auto', height: '90px' }} />
      </View>
    )
  }
}
