import type { LoanOverview, LoanOverviewFilters } from '@/types'

export function filterAndSortyArrayByObject (arr:LoanOverview[], filters:LoanOverviewFilters) {
  if (Object.values(filters).join('') === '') return arr
  const map = new Map()
  const tokens = filters.product_alias.split(/\s+/)

  const filtered = arr.filter(row => {
    if (filters.loan_type && !row.loan_type.includes(filters.loan_type)) return false
    if (filters.rate_type && filters.rate_type !== row.rate_type) return false
    const score = filters.product_alias === '' ? 1 : scoreByMatch(tokens, row.product_alias)
    if (!score) return false
    map.set(row, score)

    return true
  })
  if (!map.size) return filtered

  return filtered.sort((one, two) => map.get(two) - map.get(one))
}

function scoreByMatch (tokens:string[], target = '') {
  return tokens.reduce((acc, token) => acc + (target.match(new RegExp(token, 'i')) ? 1 : 0), 0)
}
