import { API } from 'aws-amplify'

import type { Loan, LoanVariant, LoanVariantBody } from '@/types'

const headers = { 'content-type': 'application/json' }

type LoanVariantNoId = Omit<LoanVariantBody, 'id'>

export function getLoanVariants (loanId:string):Promise<Loan> {
  return API.get('API', `/loans/${loanId}`, {}).then(loanResponseToModel)
}

export function createLoanVariant (loanId:string, body:LoanVariantNoId):Promise<{id: string}> {
  return API.post('API', `/loans/${loanId}/variants`, { body, headers })
}

export function updateLoanVariant (loanId:string, variantId:string, body:LoanVariantNoId):Promise<LoanVariant> {
  return API.put('API', `/loans/${loanId}/variants/${variantId}`, { body, headers })
}

export function deleteLoanVariant (loanId:string, variantId:string):Promise<string> {
  return API.del('API', `/loans/${loanId}/variants/${variantId}`, {})
}

function loanResponseToModel (item:Loan):Loan {
  item.loan_type = (Array.isArray(item.loan_type) ? item.loan_type : [item.loan_type]).filter(Boolean)
  item.loan_variants.forEach(x => {
    if (x.durations.length === 1) {
      x.durations = [x.durations[0], x.durations[0]]
    } else {
      x.durations = [x.durations[0], x.durations.slice(-1)[0]]
    }
  })

  return item
}
