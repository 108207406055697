import type { PropsWithChildren } from 'react'
import { Box, Button } from '@mui/material'
import type { GridCellEditCommitParams, GridColumns } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'

import { useAppDispatch, useRates } from '@/hooks'
import { actions as loanVariantsActions } from '@/redux/loanVariants/slice'
import { actions as modalActions } from '@/redux/modal/slice'
import type { LoanVariant } from '@/types'
import { formatNumber, getRateNameFromId, loanVariantModelToRequestBody } from '@/utils'

type Props = PropsWithChildren<{
  data: LoanVariant[]
}>

interface ColumnProps {
  onEdit: (item:LoanVariant)=>void
  onDuplicate: (item:LoanVariant)=>void
  onDelete: (item:LoanVariant)=>void
  getRateName: (id:string)=>string | undefined
}

const columnFactory = ({ onEdit, onDuplicate, onDelete, getRateName }:ColumnProps) => [
  {
    field: 'sort_index',
    headerName: 'ordine',
    flex: 1,
    editable: true,
    valueParser (value) {
      return Number(value)
    }
  },
  {
    field: 'spread',
    headerName: 'spread',
    flex: 1,
    editable: true,
    valueGetter ({ value }) {
      return formatNumber(
        value.toString().replace(',', '.')
      )
    }
  },
  {
    field: 'rate_id',
    headerName: 'indice',
    flex: 1,
    valueGetter ({ row }) {
      return getRateName(row.rate_id)
    }
  },
  {
    field: 'ltv_min',
    headerName: 'ltv min',
    flex: 1,
    valueGetter ({ value }) {
      return formatNumber(value)
    }
  },
  {
    field: 'ltv_max',
    headerName: 'ltv max',
    flex: 1,
    valueGetter ({ value }) {
      return formatNumber(value)
    }
  },
  {
    field: 'duration_min',
    headerName: 'durata min',
    flex: 1,
    valueGetter (params) {
      return params.row.durations[0]
    }
  },
  {
    field: 'duration_max',
    headerName: 'durata max',
    flex: 1,
    valueGetter (params) {
      return params.row.durations[1]
    }
  },
  {
    field: 'actions',
    headerName: 'actions',
    width: 350,
    renderCell: params => {
      return (
        <Box display="flex" gap={2}>
          <Button color="primary" variant="outlined" onClick={() => onEdit(params.row)}>edit</Button>
          <Button color="info" variant="outlined" onClick={() => onDuplicate(params.row)}>duplicate</Button>
          <Button color="warning" variant="outlined" onClick={() => onDelete(params.row)}>delete</Button>
        </Box>
      )
    }
  }
] as GridColumns<LoanVariant>

const PAGESIZE = 100

export function MsiVariantsTable ({ data }:Props) {
  const dispatch = useAppDispatch()
  const { data: rates } = useRates()

  if (!rates) return <></>

  const getRateName = (id:string) => getRateNameFromId(id, rates)

  function onEdit (item:LoanVariant) {
    dispatch(modalActions.MODAL_ITEM_SET({
      item,
      type: 'loanVariant',
      action: 'edit'
    }))
  }

  function onDuplicate (item:LoanVariant) {
    const { id, ...noId } = item
    dispatch(modalActions.MODAL_ITEM_SET({
      item: noId as LoanVariant,
      type: 'loanVariant',
      action: 'create'
    }))
  }

  function onDelete (item:LoanVariant) {
    dispatch(modalActions.MODAL_ITEM_SET({
      item,
      type: 'loanVariant',
      action: 'delete'
    }))
  }

  function onCellEdit ({ field, id, value }:GridCellEditCommitParams) {
    if (Number.isNaN(Number(value))) {
      value = Number(value.replace(',', '.'))
    } else {
      value = Number(value)
    }
    if (Number.isNaN(value)) return

    console.log(value)
    const row = data.find(x => x.id === id)
    if (!row || row[field as keyof typeof row] === value) return

    const item = { ...loanVariantModelToRequestBody(row), [field]: value }
    dispatch(loanVariantsActions.LOANVARIANT_ITEM_UPDATE_REQUEST(item))
  }

  return (
    <DataGrid
      rows={data}
      columns={columnFactory({ onEdit, onDuplicate, onDelete, getRateName })}
      hideFooter={data.length <= PAGESIZE}
      rowsPerPageOptions={[PAGESIZE]}
      pageSize={PAGESIZE}
      disableSelectionOnClick={true}
      disableColumnMenu={true}
      initialState={{ sorting: { sortModel: [{ field: 'sort_index', sort: 'asc' }] } }}
      onCellEditCommit={onCellEdit}
    />
  )
}
