import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { Bank, Loan, LoanVariant, Rate } from '@/types'

export const { actions, reducer } = createSlice({
  name: 'modal',
  initialState: { action: '', type: '', item: '' } as ModalActionPayload,
  reducers: {
    MODAL_ITEM_SET (state, action:PayloadAction<ModalActionPayload>) {
      return action.payload
    },
    MODAL_ITEM_RESET () {
      return { action: '', type: '', item: '' }
    }
  }
})

type modalActions ='create' | 'delete' | 'edit'

interface ModalBank {
  action: modalActions
  type: 'bank'
  item: Bank
}
interface ModalLoan {
  action: modalActions
  type: 'loan'
  item: Loan
}
interface ModalLoanVariant {
  action: modalActions
  type: 'loanVariant'
  item: LoanVariant
}
interface ModalRate {
  action: modalActions
  type: 'rate'
  item: Rate
}
interface ModalNope {
  action: ''
  type: ''
  item: ''
}

type ModalActionPayload = ModalBank | ModalLoan | ModalLoanVariant | ModalRate | ModalNope
