import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMatch } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Divider, Typography } from '@mui/material'

import { MsiFormNumber } from '@/components/forms/controls/MsiFormNumber'
import { MsiFormRange } from '@/components/forms/controls/MsiFormRange'
import { MsiFormSelect } from '@/components/forms/controls/MsiFormSelect'
import { MsiModal } from '@/components/modals/MsiModal'
import { useAppDispatch, useAppSelector, useLoanVariants, useRates } from '@/hooks'
import { actions as loanVariantActions } from '@/redux/loanVariants/slice'
import { actions as modalActions } from '@/redux/modal/slice'
import type { LoanVariant } from '@/types'
import {
  debounce, getRateIdFromName,
  getRateNameFromId, getRateNamesOptionsFromType,
  getRateValueFromName, loanVariantModelToRequestBody
} from '@/utils'
import { schema } from './schema'

export function MsiFormLoanVariant () {
  const dispatch = useAppDispatch()
  const { action, type, item } = useAppSelector(state => state.modal)
  const { control, getValues, setValue, watch, trigger, formState: { isValid, isDirty } } = useForm({
    mode: 'all',
    defaultValues: item as LoanVariant,
    resolver: yupResolver(schema)
    // reValidateMode: 'onBlur'
  })
  const loanId = useMatch('banks/:bankId/loans/:loanId/variants')?.params.loanId as string
  const { data: loan } = useLoanVariants(loanId)
  const { data: rates } = useRates()

  const values = watch()
  const debouncedValidation = useCallback(debounce(trigger, 500), [])

  useEffect(() => debouncedValidation.cancel, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      schema.validate(values).catch(ko => {
        console.log(ko)
      })
    }
    // this is for cascading validation on other fields
    // other than the one being edited
    if (isDirty) debouncedValidation()
  }, [JSON.stringify(values), isDirty])

  useEffect(() => {
    // sets rate value and id if following useEffect below or changing rate_name
    if (!rates?.length || !values.rate_name) return
    const rateValue = getRateValueFromName(values.rate_name, rates)
    setValue('rate_value', rateValue ?? 0)

    const rateId = getRateIdFromName(values.rate_name, rates)
    setValue('rate_id', rateId || '')
  }, [values.rate_name, rates])

  useEffect(() => {
    // sets rate name based on existing id if just opening the form in edit mode
    if (isDirty || !rates || !values.rate_id) return
    const rateName = getRateNameFromId(values.rate_id, rates)
    setValue('rate_name', rateName ?? '')
  }, [rates])

  const ratesOptions = useMemo(() => {
    if (!rates?.length || !loan?.rate_type) return []

    return getRateNamesOptionsFromType(loan?.rate_type, rates)
  }, [loan?.rate_type, rates])

  if (!rates || !loan) {
    return <></>
  }

  function dismiss () {
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  function confirm () {
    const item = loanVariantModelToRequestBody(getValues())
    if (action === 'edit') {
      dispatch(loanVariantActions.LOANVARIANT_ITEM_UPDATE_REQUEST(item))
    }
    if (action === 'create') {
      dispatch(loanVariantActions.LOANVARIANT_ITEM_CREATE_REQUEST(item))
    }
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  return (
    <MsiModal
      primaryBtnProps={{ children: 'confirm', onClick: confirm, disabled: !isValid }}
      secondaryBtnProps={{ children: 'cancel', onClick: dismiss }}
      title={`${action} ${type}`}
      contentSx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridAutoRows: '62px',
        columnGap: '20px',
        rowGap: '25px'
      }}
      >
        <MsiFormNumber
          label="Spread"
          name="spread"
          control={control}
          required
        />
        <MsiFormSelect
          label="Nome Indice"
          name="rate_name"
          control={control}
          options={ratesOptions}
          required
        />
        <MsiFormNumber
          label="Valore Indice"
          name="rate_value"
          control={control}
          numberFormatProps={{
            suffix: '%'
          }}
          inputProps={{
            readOnly: true
          }}
          required
        />
      </Box>
      <Divider sx={{ margin: '30px 0 20px' }} textAlign="center">
        <Typography component="label"
          sx={{
            background: theme => theme.palette.primary.main,
            padding: '0.3em'
          }}
        >
          LTV
        </Typography>
      </Divider>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridAutoRows: '62px',
        columnGap: '20px',
        rowGap: '25px'
      }}
      >
        <MsiFormNumber
          label="LTV min"
          name="ltv_min"
          control={control}
          numberFormatProps={{
            suffix: '%'
          }}
          required
        />
        <MsiFormNumber
          label="LTV max"
          name="ltv_max"
          control={control}
          numberFormatProps={{
            suffix: '%'
          }}
          required
        />
      </Box>
      <Divider sx={{ margin: '30px 0 20px' }} textAlign="center">
        <Typography component="label"
          sx={{
            background: theme => theme.palette.primary.main,
            padding: '0.3em'
          }}
        >
          DURATION
        </Typography>
      </Divider>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 1em'
      }}
      >
        <MsiFormRange
          name="durations"
          control={control}
          step={5}
          marks
          valueLabelDisplay="on"
          min={5}
          max={50}
          defaultValue={[5, 15]}
          sx={{ marginTop: '1.5em' }}
        />
      </Box>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </MsiModal>
  )
}
