
import { all, call, put, takeLatest } from 'redux-saga/effects'

import * as services from '@/services/bank'
import { actions } from './slice'

function* bankItemCreateRequest ({ payload }:ReturnType<typeof actions.BANK_ITEM_CREATE_REQUEST>) {
  try {
    const resp:string = yield call(services.createBank, payload.data)
    yield put(actions.BANK_ITEM_CREATE_SUCCESS(resp))
  } catch (error) {
    yield put(actions.BANK_ITEM_CREATE_FAILURE((error as Error).message))
  }
}
function* bankItemUpdateRequest ({ payload }:ReturnType<typeof actions.BANK_ITEM_UPDATE_REQUEST>) {
  try {
    const resp:{id: string} = yield call(services.updateBank, payload.id, payload.data)
    yield put(actions.BANK_ITEM_UPDATE_SUCCESS(resp))
  } catch (error) {
    yield put(actions.BANK_ITEM_UPDATE_FAILURE((error as Error).message))
  }
}
function* bankItemDeleteRequest ({ payload }:ReturnType<typeof actions.BANK_ITEM_DELETE_REQUEST>) {
  try {
    yield call(services.deleteBank, payload.id)
    yield put(actions.BANK_ITEM_DELETE_SUCCESS())
  } catch (error) {
    yield put(actions.BANK_ITEM_DELETE_FAILURE((error as Error).message))
  }
}

export function* banksSagas () {
  yield all([
    takeLatest(actions.BANK_ITEM_CREATE_REQUEST.type, bankItemCreateRequest),
    takeLatest(actions.BANK_ITEM_UPDATE_REQUEST.type, bankItemUpdateRequest),
    takeLatest(actions.BANK_ITEM_DELETE_REQUEST.type, bankItemDeleteRequest)
  ])
}
