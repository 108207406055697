/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'

import type { Loan, LoanBody } from '@/types'

export const { actions, reducer } = createSlice({
  name: 'loans',
  initialState: { loading: false, revalidate: false, success: false, error: '' },
  reducers: {
    LOAN_ITEM_CREATE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '' }
      },
      prepare: (data:LoanBody) => ({ payload: { data } })
    },
    LOAN_ITEM_CREATE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    LOAN_ITEM_CREATE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    LOAN_ITEM_UPDATE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '' }
      },
      prepare: ({ id, rate_type, bank_id, ...data }:LoanBody) => ({ payload: { id, data } })
    },
    LOAN_ITEM_UPDATE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    LOAN_ITEM_UPDATE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    LOAN_ITEM_DELETE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '' }
      },
      prepare: (id:string) => ({ payload: { id } })
    },
    LOAN_ITEM_DELETE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    LOAN_ITEM_DELETE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    LOAN_ITEM_CLONE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '' }
      },
      prepare: (loan:Loan) => ({ payload: { loan } })
    },
    LOAN_ITEM_CLONE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    LOAN_ITEM_CLONE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    RESET: () => {
      return { loading: false, revalidate: false, success: false, error: '' }
    }
  }
})
