import { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField } from '@mui/material'
import { Storage } from 'aws-amplify'

import { MsiFormInput } from '@/components/forms/controls/MsiFormInput'
import { MsiModal } from '@/components/modals/MsiModal'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { actions as bankActions } from '@/redux/banks/slice'
import { actions as modalActions } from '@/redux/modal/slice'
import { actions as uiActions } from '@/redux/ui/slice'
import type { Bank } from '@/types/bank'
import { bankModelToRequestBody } from '@/utils'
import { schema } from './schema'

export function MsiFormBank () {
  const dispatch = useAppDispatch()
  const { action, type, item } = useAppSelector(state => state.modal)
  const latestBankId = useAppSelector(state => state.banks.latestBankId)
  const { control, getValues, formState: { isValid, isDirty } } = useForm({
    mode: 'all',
    defaultValues: item as Bank,
    resolver: yupResolver(schema)
  })
  const [file, setFile] = useState<File>()

  function dismiss () {
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  function confirm () {
    const item = bankModelToRequestBody(getValues())
    if (action === 'edit') {
      if (!isDirty) {
        return uploadLogo(item.id)
      }
      dispatch(bankActions.BANK_ITEM_UPDATE_REQUEST(item))
    }
    if (action === 'create') {
      dispatch(bankActions.BANK_ITEM_CREATE_REQUEST(item))
    }
  }

  function onFileChosen (e:ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]
    if (!file) return

    setFile(file)
  }

  function uploadLogo (bankId:string) {
    dispatch(uiActions.UI_LOADING_SET(true))
    Storage.put(`logos/${bankId}`, file, {
      contentType: file?.type
    })
      .then(() => {
        dispatch(uiActions.UI_SUCCESS())
      })
      .catch(err => {
        dispatch(uiActions.UI_FAILURE('Unexpected error while uploading'))
        console.error(err)
      })
      .finally(() => {
        dispatch(uiActions.UI_LOADING_SET(false))
        dismiss()
      })
  }

  useEffect(() => {
    if (!latestBankId || !file) return
    uploadLogo(latestBankId)
  }, [latestBankId])

  return (
    <MsiModal
      primaryBtnProps={{ children: 'confirm', onClick: confirm, disabled: !isValid || !file }}
      secondaryBtnProps={{ children: 'cancel', onClick: dismiss }}
      title={`${action} ${type}`}
    >
      <MsiFormInput
        label="Nome Banca"
        name="bank_name"
        control={control}
        required
      />
      <TextField
        type="file"
        label='Logo'
        onChange={onFileChosen}
        inputProps={{ accept: 'image/png, image/jpeg' }}
        InputLabelProps={{ shrink: true }}
        sx={{ marginTop: '20px' }}
      />
    </MsiModal>
  )
}
