import type { ComponentProps } from 'react'
import type { UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { TextField } from '@mui/material'

type x = UseControllerProps<any>
type y = ComponentProps<typeof TextField>

type Props = x & y & {
  label: string,
  readOnly?: boolean
}

export function MsiFormTextArea (props:Props) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController(props)

  return (
    <TextField
      onChange={onChange}
      onBlur={onBlur}
      value={value || ''}
      inputRef={ref}
      multiline
      error={!!error}
      variant="outlined"
      helperText={error?.message}
      {...props}
    />
  )
}
