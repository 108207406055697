import { combineReducers } from '@reduxjs/toolkit'

import { reducer as banksReducer } from './banks/slice'
import { reducer as loansReducer } from './loans/slice'
import { reducer as loanVariantsReducer } from './loanVariants/slice'
import { reducer as modalReducer } from './modal/slice'
import { reducer as ratesReducer } from './rates/slice'
import { reducer as uiReducer } from './ui/slice'

export const rootReducer = combineReducers({
  banks: banksReducer,
  loans: loansReducer,
  loanVariants: loanVariantsReducer,
  ui: uiReducer,
  modal: modalReducer,
  rates: ratesReducer
})
