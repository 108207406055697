import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

export function MsiNavRates () {
  const location = useLocation()
  const navigate = useNavigate()

  const isRates = location.pathname === '/rates'

  function goAway () {
    if (history.length > 1) {
      navigate(-1)
    } else {
      navigate('/banks')
    }
  }

  if (isRates) {
    return (
      <Button variant="outlined" color="info" onClick={goAway}>
        go back
      </Button>
    )
  }

  return (
    <Link to={'/rates'}>
      <Button variant="outlined" color="info">
        Manage rates
      </Button>
    </Link>
  )
}
