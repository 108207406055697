/* eslint-disable camelcase */
import { Bank, BankBody, Loan, LoanBody, LoanVariant, LoanVariantBody, Rate, RateBody } from '@/types'

export function rateModelToRequestBody (item:Rate):RateBody {
  const { id, rate_name, rate_type, rate_value } = item

  return { id, rate_name, rate_type, rate_value }
}

export function bankModelToRequestBody (item:Bank):BankBody {
  const { id, bank_name } = item

  return { id, bank_name }
}

export function loanModelToRequestBody (item:Loan):LoanBody {
  const {
    id, appraisal, rate_type, high_energy_classes_only, administrative_taxes, bank_id,
    details, min_loan_value, max_loan_value, age_min, age_max, end_age_max, loan_type,
    product_name, product_alias, investigation, fire_insurance, counties, voucher
  } = item

  return {
    id,
    appraisal,
    rate_type,
    high_energy_classes_only: high_energy_classes_only ?? false,
    voucher: voucher ?? false,
    administrative_taxes,
    bank_id,
    details,
    min_loan_value,
    max_loan_value,
    age_min,
    age_max,
    end_age_max,
    loan_type,
    product_name,
    product_alias,
    investigation,
    fire_insurance,
    counties
  }
}

export function loanVariantModelToRequestBody (item:LoanVariant):LoanVariantBody {
  const { id, loan_id, rate_id, ltv_min, ltv_max, durations, spread, sort_index } = item

  return { id, loan_id, rate_id, ltv_min, ltv_max, durations: range(durations, 5), spread, sort_index }
}

function range (arr:number[], multiple:number) {
  if (arr[0] === arr[1]) return [arr[0]]

  const n = ((arr[1] - arr[0]) / multiple) - 1
  const fill = Array(n).fill('')
    .map((_, idx) => arr[0] + (idx + 1) * multiple)

  return [arr[0], ...fill, arr[1]]
}
