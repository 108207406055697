import { all, call, spawn } from 'redux-saga/effects'

import { banksSagas } from './banks/sagas'
import { loansSagas } from './loans/sagas'
import { loanVariantsSagas } from './loanVariants/sagas'
import { ratesSagas } from './rates/sagas'

export function* rootSaga () {
  const sagas = [
    banksSagas,
    loansSagas,
    loanVariantsSagas,
    ratesSagas
  ]

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.error(e)
          }
        }
      })
    )
  )
}
