import { Box, Button } from '@mui/material'

import { MsiNavRates } from '@/components/MsiNavRates'
import { MsiBanksTable } from '@/components/tables/MsiBanksTable'
import { useAppDispatch, useAppSelector, useBanks } from '@/hooks'
import { actions as modalActions } from '@/redux/modal/slice'
import type { Bank } from '@/types'

export function MsiBanks () {
  const dispatch = useAppDispatch()
  const { data } = useBanks()
  const logoUpdated = useAppSelector(x => x.ui.success)

  if (!data) {
    return <></>
  }

  function createBank () {
    dispatch(modalActions.MODAL_ITEM_SET({
      action: 'create',
      type: 'bank',
      item: {} as Bank
    }))
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 2 }}>
        <Box flex={1}>
          <MsiNavRates />
        </Box>
        <div>All Banks</div>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={createBank}>
            Create Bank
          </Button>
        </Box>
      </Box>
      <MsiBanksTable key={logoUpdated.toString()} data={data} />

    </>
  )
}
