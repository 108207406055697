import { Typography } from '@mui/material'

import { useAppDispatch, useAppSelector } from '@/hooks'
import { actions as bankActions } from '@/redux/banks/slice'
import { actions as loanActions } from '@/redux/loans/slice'
import { actions as loanVariantsActions } from '@/redux/loanVariants/slice'
import { actions as modalActions } from '@/redux/modal/slice'
import { actions as rateActions } from '@/redux/rates/slice'
import { MsiModal } from './MsiModal'

export function MsiModalDelete () {
  const dispatch = useAppDispatch()
  const modal = useAppSelector(state => state.modal)

  function dismiss () {
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  const itemName = modal.type === 'bank'
    ? modal.item.bank_name
    : modal.type === 'loan'
      ? modal.item.product_name
      : modal.type === 'rate'
        ? modal.item.rate_name
        : ''

  function confirm () {
    switch (modal.type) {
      case 'bank': {
        dispatch(bankActions.BANK_ITEM_DELETE_REQUEST(modal.item.id))
        break
      }
      case 'loan': {
        dispatch(loanActions.LOAN_ITEM_DELETE_REQUEST(modal.item.id))
        break
      }
      case 'loanVariant': {
        dispatch(loanVariantsActions.LOANVARIANT_ITEM_DELETE_REQUEST(modal.item.loan_id, modal.item.id))
        break
      }
      case 'rate': {
        dispatch(rateActions.RATE_ITEM_DELETE_REQUEST(modal.item.id))
        break
      }
    }
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  return (
    <MsiModal
      primaryBtnProps={{ children: 'confirm', onClick: confirm }}
      secondaryBtnProps={{ children: 'cancel', onClick: dismiss }}
      title={`${modal.action} ${modal.type}`}
    >
      <Typography variant="h6">
        Are you sure you want to delete
        {itemName
          ? <Typography component="label" variant="h6"
              sx={{
                background: theme => theme.palette.primary.main,
                padding: '0.1em 0.2em',
                marginLeft: '0.2em'
              }}
            >
            {itemName}
          </Typography>
          : ' this'
      }
        ?
      </Typography>
      {modal.type === 'rate' && (
        <Typography variant="h6" color="red">
          Deleting this will also delete ALL related loans.
        </Typography>
      )}
    </MsiModal>
  )
}
