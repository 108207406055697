import type { ComponentProps } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { TextField } from '@mui/material'

type x = UseControllerProps<any>
type y = ComponentProps<typeof TextField>

type Props = x & y & {
  label: string,
  readOnly?: boolean
}

export function MsiFormCheckbox (props:Props) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController(props)

  const { sx, ...otherProps } = props
  const _sx = {
    ...sx || {},
    height: '56px',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      '& input': {
        cursor: 'pointer'
      }
    }
  }

  return (
    <TextField
      onChange={onChange}
      onBlur={onBlur}
      value={value || false}
      inputProps={{
        checked: value || false
      }}
      inputRef={ref}
      error={!!error}
      type="checkbox"
      variant="outlined"
      helperText={error?.message}
      sx={_sx}
      {...otherProps}
    />
  )
}
