export const formatDateTime = (value:string) => {
  try {
    return new Intl.DateTimeFormat('it-IT', {
      timeZone: 'Europe/Rome', dateStyle: 'short', timeStyle: 'short'
    }).format(new Date(value))
  } catch (err) {
    return ''
  }
}

export function getNumberFormatConfig () {
  const parts = Intl.NumberFormat().formatToParts(100000.2)

  return {
    thousandSeparator: parts.find(x => x.type === 'group')?.value,
    decimalSeparator: parts.find(x => x.type === 'decimal')?.value
  }
}

export function formatNumberFixed (value:number) {
  return Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
}

export function formatNumber (value:number) {
  return Intl.NumberFormat('it').format(value)
}
