import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { Auth } from 'aws-amplify'

import { ReactComponent as Logo } from '@/assets/logo.svg'
import { useAppSelector } from '@/hooks'
import { loadingSelector } from '@/redux/rootSelectors'
import { logout } from '@/utils/auth'
import { MsiBreadcrumb } from './MsiBreadcrumb'

export function MsiHeader () {
  const loading = useAppSelector(loadingSelector)
  const [user, setUser] = useState('')

  useEffect(() => {
    Auth.currentUserInfo().then(userInfo => {
      setUser(userInfo.username)
    })
  }, [])

  return (
    <Box component="header" sx={{
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: theme => `1px solid ${theme.palette.divider}`,
      position: 'relative'
    }}
    >
      {loading && <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
      <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', gap: '10px' }}>
        <Link to="/">
          <Typography
            color={theme => theme.palette.primary.main}
            fontSize={25}
            sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            <Logo style={{ width: 'auto', height: '40px' }} />
            Backoffice
          </Typography>
        </Link>
      </Box>
      <MsiBreadcrumb />
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 3 }}>
        <Typography>{user}</Typography>
        <Button variant="contained" onClick={logout}>
          Logout
        </Button>
      </Box>
    </Box>
  )
}
