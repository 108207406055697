/* eslint-disable newline-per-chained-call */
import * as yup from 'yup'

import { investigationTypeOptions, loanTypeOptions, rateTypeOptions } from '@/components/forms/consts'

const getValues = (arr:{value: string, label: string}[]) => arr.map(({ value }) => value)
const investigationTypeValues = getValues(investigationTypeOptions)
const loanTypeValues = getValues(loanTypeOptions)
const rateTypeValues = getValues(rateTypeOptions)

export const schema = yup.object().shape({
  bank_id: yup.string().required(),
  product_name: yup.string().required('required'),
  product_alias: yup.string(),
  loan_type: yup.array().of(yup.string().oneOf(loanTypeValues)).min(1, 'required'),
  rate_type: yup.string().oneOf(rateTypeValues, `one of: ${rateTypeValues.join(', ')}`).required('required'),
  age_min: yup.number().nullable().integer().min(0).required('required'),
  age_max: yup.number().nullable().integer().min(yup.ref('age_min')).required('required'),
  end_age_max: yup.number().nullable().integer().min(yup.ref('age_max')).required('required'),
  high_energy_classes_only: yup.boolean(),
  min_loan_value: yup.number().nullable().min(0).required('required'),
  max_loan_value: yup.number().nullable().min(yup.ref('min_loan_value')).required('required'),
  investigation: yup.lazy((item) => {
    const type = yup.string().oneOf(investigationTypeValues, `one of: ${investigationTypeValues.join(', ')}`).required('required')

    if (item?.type === 'fixed') {
      return yup.object().shape({
        type,
        value: yup.number().nullable().min(0).required('required')
      })
    }
    if (item?.type === 'variable') {
      return yup.object().shape({
        type,
        value: yup.number().nullable().min(0).max(1).required('required'),
        min: yup.number().nullable().min(0).required('required'),
        max: yup.number().nullable().min(0).min(yup.ref('min')).required('required')
      })
    }

    return yup.object().shape({ type }).required('required')
  }),
  appraisal: yup.number().nullable().min(0).required('required'),
  administrative_taxes: yup.number().nullable().min(0).required('required'),
  fire_insurance: yup.number().nullable().min(0).required('required'),
  details: yup.object().shape({
    insurance: yup.string(),
    early_termination_penalty: yup.string(),
    requested_guarantee: yup.string(),
    time_of_disbursement: yup.string(),
    notes: yup.string(),
    rate_calculation: yup.string()
  }).optional(),
  counties: yup.array().of(yup.string()).min(1)
})
