import type { ComponentProps } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { FormControl, FormHelperText, Slider } from '@mui/material'

type BaseType = UseControllerProps<any> & ComponentProps<typeof Slider>

interface Props extends BaseType {
  readOnly?: boolean
  name: string
}

export function MsiFormRange ({ sx, ...props }:Props) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isTouched }
  } = useController(props)

  return (
    <FormControl sx={sx} required>
      <Slider
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
      />
      <FormHelperText error>{(value || isTouched) && error?.message}</FormHelperText>
    </FormControl>
  )
}
