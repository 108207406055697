import type { ComponentProps } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'

type BaseType = UseControllerProps<any> & ComponentProps<typeof Select>

interface Props extends BaseType {
  label: string,
  readOnly?: boolean
  options: {value: string | number, label: string}[]
  nullable?: boolean
}

export function MsiFormSelect ({ sx, required, ...props }:Props) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isTouched }
  } = useController(props)

  return (
    <FormControl sx={sx} required>
      <InputLabel>{props.label}</InputLabel>
      <Select
        onChange={onChange}
        onBlur={onBlur}
        value={value || ''}
        error={(value || isTouched) && !!error}
        variant="outlined"
        {...props}
      >
        {(props.nullable)
          ? <MenuItem value={''}>&nbsp;</MenuItem>
          : <MenuItem value={''} disabled></MenuItem>
        }
        {props.options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
      <FormHelperText error>{(value || isTouched) && error?.message}</FormHelperText>
    </FormControl>
  )
}
