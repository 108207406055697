import { useParams } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import { MsiNavRates } from '@/components/MsiNavRates'
import { MsiVariantsTable } from '@/components/tables/MsiVariantsTable'
import { useAppDispatch, useLoanVariants } from '@/hooks'
import { actions as modalActions } from '@/redux/modal/slice'
import type { LoanVariant } from '@/types'

export function MsiLoanVariants () {
  const { loanId } = useParams() as {loanId: string}
  const dispatch = useAppDispatch()
  const { data } = useLoanVariants(loanId)

  if (!data) {
    return <></>
  }

  function createLoanVariant () {
    dispatch(modalActions.MODAL_ITEM_SET({
      action: 'create',
      type: 'loanVariant',
      item: { loan_id: loanId } as LoanVariant
    }))
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 2 }}>
        <Box flex={1}>
          <MsiNavRates />
        </Box>
        <div>Loan: <Typography component="label"
          variant="h6"
          sx={{
            marginLeft: 1,
            color: theme => theme.palette.primary.main
          }}
                   >
          {data.product_name}
        </Typography>
        </div>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={createLoanVariant}>
            Create Loan Variant
          </Button>
        </Box>
      </Box>
      <MsiVariantsTable data={data.loan_variants} />
    </>
  )
}
