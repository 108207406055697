import { ComponentProps, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { useAppDispatch } from '@/hooks'
import { actions } from '@/redux/modal/slice'
import { lightTheme } from '@/styles/theme'

type Props = PropsWithChildren<{
  alignCenter?: boolean
  title?: string
  primaryBtnProps?: ComponentProps<typeof Button>
  secondaryBtnProps?: ComponentProps<typeof Button>
  modalSx?: ComponentProps<typeof Modal>['sx']
  contentSx?: ComponentProps<typeof Modal>['sx']
}>

const ModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap:30px;
  overflow: hidden;
  max-height: 75vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 5px solid ${({ theme }) => theme.palette.primary.main};
  width: 686px;
  padding: 15px;
  border-radius: 5px;
  .msi-modal-content {
    flex: 1;
    overflow: auto;
    padding: 10px 0; // 2px bottom prevents showing scrollbar when not needed
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 -15px;
    padding: 15px;
  }
  `

export function MsiModal ({
  alignCenter = false,
  title = '',
  primaryBtnProps,
  secondaryBtnProps,
  children,
  modalSx,
  contentSx
}:Props) {
  const dispatch = useAppDispatch()
  const footerStyle = alignCenter
    ? {
        justifyContent: 'center',
        gap: '24px'
      }
    : {
        justifyContent: 'space-between'
      }

  const close = () => { dispatch(actions.MODAL_ITEM_RESET()) }

  return (
    <ThemeProvider theme={lightTheme}>
      <Modal
        open={true}
        BackdropProps={{ sx: { opacity: 0.6 } }}
        onClose={close}
      >
        <ModalContainer sx={modalSx}>
          {
          title &&
            <Typography color="text.primary" variant='h4' align={`${alignCenter ? 'center' : 'left'}`}
              sx={{ textTransform: 'capitalize' }}
            >
              {title}
            </Typography>
        }
          <Box className="msi-modal-content" sx={{ alignItems: `${alignCenter ? 'center' : 'flex-start'}`, ...contentSx }}>
            {children}
          </Box>
          <Box sx={{ display: 'flex', ...footerStyle }}>
            {
            secondaryBtnProps && (
              <Button
                variant="outlined"
                color="secondary"
                {...secondaryBtnProps}
              >
              </Button>
            )
          }
            {
            primaryBtnProps && (
              <Button
                variant="contained"
                color="primary"
                {...primaryBtnProps}
              >
              </Button>
            )
          }
          </Box>
        </ModalContainer>
      </Modal>
    </ThemeProvider>

  )
}
