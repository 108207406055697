import { API } from 'aws-amplify'

import type { Bank, BankBody } from '@/types'

// window.__API = API

const headers = { 'content-type': 'application/json' }

type BankNoId = Omit<BankBody, 'id'>

export function getBanks ():Promise<Bank[]> {
  return API.get('API', '/banks', {})
}

export function createBank (body:BankNoId):Promise<{id: string}> {
  return API.post('API', '/banks', { body, headers })
}

export function updateBank (bankId:string, body:BankNoId):Promise<Bank> {
  return API.put('API', `/banks/${bankId}`, { body, headers })
}

export function deleteBank (bankId:string):Promise<string> {
  return API.del('API', `/banks/${bankId}`, {})
}
