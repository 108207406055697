import { API } from 'aws-amplify'

import type { Bank, LoanBody, LoanOverview } from '@/types'

const headers = { 'content-type': 'application/json' }

type LoanNoId = Omit<LoanBody, 'id'>

interface BankWithLoans extends Bank {
  loans: LoanOverview[]
}

export function getLoans (bankId:string):Promise<BankWithLoans> {
  return API.get('API', `/banks/${bankId}`, {})
    .then((x:BankWithLoans) => {
      x.loans.forEach(loan => {
        loan.loan_type = (
          Array.isArray(loan.loan_type)
            ? loan.loan_type
            : [loan.loan_type]
        ).filter(Boolean)
      })

      return x
    })
}

export function createLoan (body:LoanNoId):Promise<string> {
  return API.post('API', '/loans', { body, headers })
}

export function updateLoan (id:string, body:LoanNoId):Promise<string> {
  return API.put('API', `/loans/${id}`, { body, headers })
}

export function deleteLoan (id:string):Promise<string> {
  return API.del('API', `/loans/${id}`, {})
}
