import { API } from 'aws-amplify'

import type { Rate, RateBody } from '@/types'

const headers = { 'content-type': 'application/json' }

type RateNoId = Omit<RateBody, 'id'>

export function getRates ():Promise<Rate[]> {
  return API.get('API', '/rates', {})
}

export function createRate (body:RateNoId):Promise<{id: string}> {
  return API.post('API', '/rates', { body, headers })
}

export function updateRate (rateId:string, body:RateNoId):Promise<Rate> {
  return API.put('API', `/rates/${rateId}`, { body, headers })
}

export function deleteRate (rateId:string):Promise<string> {
  return API.del('API', `/rates/${rateId}`, {})
}
