import type { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import type { GridColumns } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'

import { loanTypeLabelShortMap, rateTypeLabelsMap } from '@/components/forms/consts'
import { useAppDispatch } from '@/hooks'
import { actions as loansActions } from '@/redux/loans/slice'
import { actions as modalActions } from '@/redux/modal/slice'
import { actions as uiActions } from '@/redux/ui/slice'
import { getLoanVariants } from '@/services/loanVariant'
import type { Loan, LoanOverview } from '@/types'

type Props = PropsWithChildren<{
  data: LoanOverview[]
}>

interface ColumnProps {
  onEdit: (item:LoanOverview)=>void
  onDuplicate: (item:LoanOverview)=>void
  onDelete: (item:LoanOverview)=>void
}

const columnFactory = ({ onEdit, onDuplicate, onDelete }:ColumnProps) => [
  {
    field: 'product_name',
    headerName: 'nome prodotto',
    flex: 1
  },
  {
    field: 'product_alias',
    headerName: 'nome prodotto interno',
    flex: 1
  },
  {
    field: 'loan_type',
    headerName: 'tipo mutuo',
    flex: 1,
    valueFormatter ({ value }) {
      return (value as Loan['loan_type']).map(x => loanTypeLabelShortMap[x])
        .join(', ')
    }
  },
  {
    field: 'rate_type',
    headerName: 'tasso',
    flex: 1,
    valueFormatter ({ value }) {
      return rateTypeLabelsMap[value]
    }
  },
  {
    field: 'actions',
    headerName: 'actions',
    width: 350,
    renderCell: params => {
      return (
        <Box display="flex" gap={2}>
          <Button color="primary" variant="outlined" onClick={() => onEdit(params.row)}>edit</Button>
          <Button color="info" variant="outlined" onClick={() => onDuplicate(params.row)}>duplicate</Button>
          <Button color="warning" variant="outlined" onClick={() => onDelete(params.row)}>delete</Button>
        </Box>
      )
    }
  },
  {
    field: 'view',
    headerName: 'view',
    width: 170,
    renderCell: params => {
      return (
        <Box display="flex" gap={2}>
          <Link to={`${params.row.id}/variants`}>
            <Button color="secondary" variant="outlined">view variants</Button>
          </Link>
        </Box>
      )
    }
  }
] as GridColumns<LoanOverview>

const PAGESIZE = 100

export function MsiLoansTable ({ data }:Props) {
  const dispatch = useAppDispatch()

  async function fetchLoan (item:LoanOverview) {
    dispatch(uiActions.UI_LOADING_SET(true))
    const resp = await getLoanVariants(item.id)
      .catch((error) => {
        dispatch(loansActions.LOAN_ITEM_UPDATE_FAILURE(error.message))
      })
    dispatch(uiActions.UI_LOADING_SET(false))

    return resp
  }

  async function onEdit (item:LoanOverview) {
    const resp = await fetchLoan(item)
    if (!resp) return

    const loan = resp
    dispatch(modalActions.MODAL_ITEM_SET({
      item: loan,
      type: 'loan',
      action: 'edit'
    }))
  }

  async function onDuplicate (item:LoanOverview) {
    const resp = await fetchLoan(item)
    if (!resp) return

    dispatch(loansActions.LOAN_ITEM_CLONE_REQUEST(resp))
  }

  function onDelete (item:LoanOverview) {
    dispatch(modalActions.MODAL_ITEM_SET({
      item: item as unknown as Loan,
      type: 'loan',
      action: 'delete'
    }))
  }

  return (
    <DataGrid
      rows={data}
      columns={columnFactory({ onEdit, onDuplicate, onDelete })}
      hideFooter={data.length <= PAGESIZE}
      rowsPerPageOptions={[PAGESIZE]}
      pageSize={PAGESIZE}
      disableSelectionOnClick={true}
      disableColumnMenu={true}
    // initialState={{ sorting: { sortModel: [{ field: 'product_name', sort: 'asc' }] } }}
    />
  )
}
