import type { ComponentProps } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@mui/material'

export function MsiBreadcrumb (props:ComponentProps<typeof Breadcrumbs>) {
  const location = useLocation()

  const matchedFragments = getMatchedFragments(location.pathname)

  return (
    <Breadcrumbs {...props}>
      {matchedFragments.map((obj, idx) =>
        idx + 1 < matchedFragments.length
          ? <Link key={obj.url} to={obj.url}>
            <Typography color={theme => theme.palette.primary.main}
              sx={{ textDecoration: 'underline' }}
            >{obj.label}
            </Typography>
          </Link>
          : <Typography key={obj.url} component="label">
            {obj.label}
          </Typography>
      )}
    </Breadcrumbs>
  )
}

const fragments = [
  {
    label: 'Banks',
    rgx: /^\/banks/
  },
  {
    label: 'Loans',
    rgx: /^\/banks\/.*\/loans/
  },
  {
    label: 'Variants',
    rgx: /^\/banks\/.*\/loans\/.*\/variants/
  },
  {
    label: 'Rates',
    rgx: /^\/rates/
  }
]

function getMatchedFragments (pathname:string) {
  return fragments.reduce((acc, fragment) => {
    const match = pathname.match(fragment.rgx)?.[0]
    if (match) { acc = [...acc, { label: fragment.label, url: match }] }

    return acc
  }, [] as {label: string, url: string}[])
}
