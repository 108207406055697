import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export const { actions, reducer } = createSlice({
  name: 'ui',
  initialState: { loading: false, success: false, error: '' },
  reducers: {
    UI_LOADING_SET (state, action:PayloadAction<boolean>) {
      return { ...state, loading: action.payload }
    },
    UI_SUCCESS (state) {
      return { ...state, success: true, error: '' }
    },
    UI_FAILURE (state, action:PayloadAction<string>) {
      return { ...state, success: true, error: action.payload }
    },
    RESET () {
      return { loading: false, success: false, error: '' }
    }
  }
})
