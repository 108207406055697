import type { ComponentProps } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

type BaseType = UseControllerProps<any> & ComponentProps<typeof Select>

interface Props extends BaseType {
  label: string,
  readOnly?: boolean
  options: {value: string | number, label: string}[]
  allable?: boolean
  labelAll?: string
}

export function MsiFormMultiSelect ({ sx, required, allable, labelAll, ...props }:Props) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isTouched }
  } = useController(props)

  function handleChange (e:SelectChangeEvent<string[]>) {
    if (!allable) {
      return onChange(e)
    }
    if (!e.target.value.length || e.target.value.slice(-1)[0] === '*') {
      return onChange({ target: { value: ['*'] } })
    }
    e.target.value = (e.target.value as string[]).filter(x => x !== '*')

    return onChange(e)
  }

  return (
    <FormControl sx={sx} required>
      <InputLabel>{props.label}</InputLabel>
      <Select
        onChange={handleChange}
        onBlur={onBlur}
        value={value || []}
        error={(value || isTouched) && !!error}
        variant="outlined"
        multiple
        {...props}
      >
        {(allable)
          ? <MenuItem value={'*'}>{labelAll || 'all'}</MenuItem>
          : <MenuItem value={''} disabled></MenuItem>
        }
        {props.options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
      <FormHelperText error>{(value || isTouched) && error?.message}</FormHelperText>
    </FormControl>
  )
}
