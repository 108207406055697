import type { PropsWithChildren } from 'react'
import { Box, Button, Typography } from '@mui/material'
import type { GridColumns } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'

import { rateTypeLabelsMap } from '@/components/forms/consts'
import { useAppDispatch } from '@/hooks'
import { actions } from '@/redux/modal/slice'
import type { Rate } from '@/types'
import { formatDateTime, formatNumberFixed } from '@/utils'

type Props = PropsWithChildren<{
  data: Rate[]
}>

interface ColumnProps {
  onEdit: (item:Rate)=>void
  onDelete: (item:Rate)=>void
}

const columnFactory = ({ onEdit, onDelete }:ColumnProps) => [
  {
    field: 'rate_name',
    headerName: 'nome indice',
    flex: 1
  },
  {
    field: 'rate_type',
    headerName: 'tasso',
    flex: 1,
    valueFormatter ({ value }) {
      return rateTypeLabelsMap[value]
    }
  },
  {
    field: 'rate_value',
    headerName: 'valore',
    flex: 1,
    valueFormatter ({ value }) {
      return formatNumberFixed(value)
    },
    cellClassName: 'numeric'
  },
  {
    field: 'latestUpdate',
    headerName: 'ultima modifica',
    flex: 1,
    valueGetter ({ row }) {
      return formatDateTime(row.modifiedAt || row.createdAt)
    }
  },
  {
    field: 'details',
    headerName: 'gestito da crawler',
    flex: 1,
    renderCell ({ value }) {
      return value?.value_generated_at
        ? <>
          <Typography color={theme => theme.palette.success.main} fontSize={25}>
            ✓
          </Typography>
          &nbsp;<label>{formatDateTime(value.value_generated_at)}</label>
        </>
        : <Typography color={theme => theme.palette.error.main} fontSize={20}>
          ✕
        </Typography>
    }
  },
  {
    field: 'actions',
    headerName: 'actions',
    width: 200,
    renderCell: params => {
      return (
        <Box display="flex" gap={2}>
          <Button color="primary" variant="outlined" onClick={() => onEdit(params.row)}>edit</Button>
          <Button color="warning" variant="outlined" onClick={() => onDelete(params.row)}>delete</Button>
        </Box>
      )
    }
  }
] as GridColumns<Rate>

const PAGESIZE = 100

export function MsiRatesTable ({ data }:Props) {
  const dispatch = useAppDispatch()

  function onEdit (item:Rate) {
    dispatch(actions.MODAL_ITEM_SET({
      item,
      type: 'rate',
      action: 'edit'
    }))
  }

  function onDelete (item:Rate) {
    dispatch(actions.MODAL_ITEM_SET({
      item,
      type: 'rate',
      action: 'delete'
    }))
  }

  return (
    <DataGrid
      rows={data}
      columns={columnFactory({ onEdit, onDelete })}
      hideFooter={data.length <= PAGESIZE}
      rowsPerPageOptions={[PAGESIZE]}
      pageSize={PAGESIZE}
      disableSelectionOnClick={true}
      disableColumnMenu={true}
      initialState={{ sorting: { sortModel: [{ field: 'latestUpdate', sort: 'desc' }] } }}
    />
  )
}
