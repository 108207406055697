import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from './store'

export const loadingSelector = createSelector(
  [
    (store:RootState) => store.ui,
    (store:RootState) => store.banks,
    (store:RootState) => store.loans,
    (store:RootState) => store.loanVariants,
    (store:RootState) => store.rates
  ],
  (...args) => args.some(slice => slice.loading)
)

export const successSelector = createSelector(
  [
    (store:RootState) => store.ui,
    (store:RootState) => store.banks,
    (store:RootState) => store.loans,
    (store:RootState) => store.loanVariants,
    (store:RootState) => store.rates
  ],
  (...args) => args.some(slice => slice.success)
)

export const errorSelector = createSelector(
  [
    (store:RootState) => store.ui,
    (store:RootState) => store.banks,
    (store:RootState) => store.loans,
    (store:RootState) => store.loanVariants,
    (store:RootState) => store.rates
  ],
  (...args) => args.find(slice => slice.error)?.error
)
