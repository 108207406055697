import { Rate } from '@/types'

export function getRateNamesOptionsFromType (type:string, arr:Rate[]) {
  return arr.reduce((acc, obj) => {
    if (obj.rate_type === type) {
      acc.push({
        label: obj.rate_name,
        value: obj.rate_name
      })
    }

    return acc
  }, [] as {label: string, value: string}[])
}

export function getRateValueFromName (name:string, arr:Rate[]) {
  return arr.find(x => x.rate_name === name)?.rate_value
}

export function getRateIdFromName (name:string, arr:Rate[]) {
  return arr.find(x => x.rate_name === name)?.id
}

export function getRateNameFromId (id:string, arr:Rate[]) {
  return arr.find(x => x.id === id)?.rate_name
}
