import { Box, Typography } from '@mui/material'

export function Msi404 () {
  return (
    <Box sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    >
      <Typography variant="h5">Oooops, this is not the loan you are looking for</Typography>

    </Box>
  )
}
