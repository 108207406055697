import type { ComponentProps } from 'react'
import type { UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { TextField } from '@mui/material'

import { getNumberFormatConfig } from '@/utils'

type x = UseControllerProps<any>
type y = ComponentProps<typeof TextField>
type NumberInputProps = Omit<ComponentProps<typeof NumberFormat>, 'size' | 'color'>

type Props = x & y & {
  label: string,
  readOnly?: boolean
  numberFormatProps?: NumberInputProps
}

const format = getNumberFormatConfig()

export function MsiFormNumber ({ ref, type, numberFormatProps, ...props }:Props) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isTouched }
  } = useController(props)

  return (
    <NumberFormat
      customInput={TextField}
      variant="outlined"
      value={value}
      onBlur={onBlur}
      onValueChange={(val) => {
        onChange(val.floatValue ?? null)
      }}
      helperText={(value || isTouched) && error?.message}
      error={(value || isTouched) && !!error}
      thousandSeparator={format.thousandSeparator}
      decimalSeparator={format.decimalSeparator}
      {...props}
      {...numberFormatProps}
    />
  )
}
