/* eslint-disable camelcase */
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Chip, Divider, Typography } from '@mui/material'

import {
  countiesOptions, investigationTypeOptions, loanTypeLabelShortMap,
  loanTypeOptions, rateTypeOptions
} from '@/components/forms/consts'
import { MsiFormCheckbox } from '@/components/forms/controls/MsiFormCheckbox'
import { MsiFormInput } from '@/components/forms/controls/MsiFormInput'
import { MsiFormMultiSelect } from '@/components/forms/controls/MsiFormMultiSelect'
import { MsiFormNumber } from '@/components/forms/controls/MsiFormNumber'
import { MsiFormSelect } from '@/components/forms/controls/MsiFormSelect'
import { MsiFormTextArea } from '@/components/forms/controls/MsiFormTextArea'
import { MsiModal } from '@/components/modals/MsiModal'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { actions as loanActions } from '@/redux/loans/slice'
import { actions as modalActions } from '@/redux/modal/slice'
import type { Loan } from '@/types'
import { debounce, loanModelToRequestBody, removeNullFields } from '@/utils'
import { schema } from './schema'

export function MsiFormLoan () {
  const dispatch = useAppDispatch()
  const { action, type, item } = useAppSelector(state => state.modal)
  const {
    control, getValues, setValue, watch, trigger, getFieldState,
    formState: { isValid, isDirty, dirtyFields } // need to expose dirtyFields so to have getFieldState working
  } = useForm({
    mode: 'all',
    defaultValues: item as Loan,
    resolver: yupResolver(schema)
  })

  const values = watch()
  const debouncedValidation = useCallback(debounce(trigger, 500), [])

  useEffect(() => debouncedValidation.cancel, [])

  useEffect(() => {
    if (!isDirty) return
    if (process.env.NODE_ENV === 'development') {
      schema.validate(values).catch(ko => {
        console.log(ko)
      })
    }
    // this is for cascading validation on other fields
    // other than the one being edited
    debouncedValidation()
  }, [JSON.stringify(values)])

  useEffect(() => {
    if (values.investigation?.type === 'fixed') {
      setValue('investigation.min', null as unknown as number)
      setValue('investigation.max', null as unknown as number)
    }
  }, [values.investigation?.type])

  useEffect(() => {
    if (!isDirty) return
    if (values.loan_type.length === 1 && values.loan_type[0] === 'subrogation') {
      setValue('investigation.value', 0)
      if (values.investigation?.type === 'variable') {
        setValue('investigation.min', 0)
        setValue('investigation.max', 0)
      }
      setValue('fire_insurance', 0)
      setValue('appraisal', 0)
    }
  }, [values.loan_type])

  function dismiss () {
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  function confirm () {
    const item = removeNullFields(loanModelToRequestBody(getValues()))

    if (action === 'edit') {
      dispatch(loanActions.LOAN_ITEM_UPDATE_REQUEST(item))
    }
    if (action === 'create') {
      dispatch(loanActions.LOAN_ITEM_CREATE_REQUEST(item))
    }
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  return (
    <MsiModal
      primaryBtnProps={{ children: 'confirm', onClick: confirm, disabled: !isValid }}
      secondaryBtnProps={{ children: 'cancel', onClick: dismiss }}
      title={`${action} ${type}`}
      contentSx={{ display: 'flex', flexDirection: 'column' }}
      modalSx={{ width: '1200px !important', maxHeight: '90vh !important' }}
    >
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridAutoRows: 'minmax(62px, auto)',
        columnGap: '20px',
        rowGap: '25px'
      }}
      >
        <MsiFormInput
          label="Nome Prodotto"
          name="product_name"
          control={control}
          sx={{ gridColumn: 'span 2' }}
          required
        />
        <MsiFormInput
          label="Nome Prodotto Interno"
          name="product_alias"
          control={control}
          sx={{ gridColumn: 'span 2' }}
        />
        <MsiFormMultiSelect
          label="Tipo Mutuo"
          name="loan_type"
          control={control}
          options={loanTypeOptions}
          defaultValue={[]}
          sx={{ gridColumn: 'span 1' }}
          renderValue={(arr:unknown) => {
            const values = arr as (keyof typeof loanTypeLabelShortMap)[]

            return values.map(x => loanTypeLabelShortMap[x]).sort()
              .join(', ')
          }}
          required
        />
        <MsiFormSelect
          label="Tasso"
          name="rate_type"
          control={control}
          options={rateTypeOptions}
          sx={{ gridColumn: 'span 1' }}
          disabled={!!values.id}
          required
        />
        <MsiFormNumber
          label="Valore Mutuo min"
          name="min_loan_value"
          control={control}
          numberFormatProps={{
            prefix: '€'
          }}
          required
        />
        <MsiFormNumber
          label="Valore Mutuo max"
          name="max_loan_value"
          control={control}
          numberFormatProps={{
            prefix: '€'
          }}
          required
        />
        <MsiFormNumber
          label="Anni min"
          name="age_min"
          control={control}
          required
        />
        <MsiFormNumber
          label="Anni max"
          name="age_max"
          control={control}
          required
        />
        <MsiFormNumber
          label="Fine Anni max"
          name="end_age_max"
          control={control}
          required
        />
        <MsiFormCheckbox
          label="Solo Classe Energetica Alta"
          name="high_energy_classes_only"
          control={control}
          defaultChecked={false}
        />
        <MsiFormNumber
          label="Perizia"
          name="appraisal"
          control={control}
          numberFormatProps={{
            prefix: '€'
          }}
          required
        />
        <MsiFormNumber
          label="Spese Amministrative"
          name="administrative_taxes"
          control={control}
          numberFormatProps={{
            prefix: '€'
          }}
          required
        />
        <MsiFormNumber
          label="Polizza Incendio"
          name="fire_insurance"
          control={control}
          numberFormatProps={{
            prefix: '€'
          }}
          required
        />
        <MsiFormCheckbox
          label="Voucher"
          name="voucher"
          control={control}
          defaultChecked={false}
        />
        <MsiFormMultiSelect
          label="Province"
          name="counties"
          control={control}
          options={countiesOptions}
          defaultValue={['*']}
          sx={{ gridColumn: 'span 4' }}
          multiple
          autoWidth
          allable
          labelAll='Tutte'
          renderValue={(arr:unknown) => {
            const values = arr as string[]
            if (values[0] === '*') return 'Tutte'

            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 0.5, columnGap: 0.5 }}>
                {values.sort().map(code => (
                  <Chip size="small" key={code} label={code} />
                ))}
              </Box>
            )
          }}
          required
        />
      </Box>

      <Divider sx={{ margin: '30px 0 20px' }} textAlign="center">
        <Typography component="label"
          sx={{
            background: theme => theme.palette.primary.main,
            padding: '0.3em'
          }}
        >
          ISTRUTTORIA
        </Typography>
      </Divider>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridAutoRows: '62px',
        columnGap: '20px',
        rowGap: '25px'
      }}
      >
        <MsiFormSelect
          label="Tipo"
          name="investigation.type"
          control={control}
          options={investigationTypeOptions}
          defaultValue=""
          sx={{ gridColumn: 'span 1' }}
          required
        />
        {values.investigation?.type === 'fixed' &&
          <MsiFormNumber
            label="Valore"
            name="investigation.value"
            control={control}
            numberFormatProps={{
              prefix: '€'
            }}
            required
          />
        }
        {values.investigation?.type === 'variable' &&
          <>
            <MsiFormNumber
              label="Valore"
              name="investigation.value"
              control={control}
              numberFormatProps={{
                min: 0,
                max: 1
              }}
              required
            />
            <MsiFormNumber
              label="Min"
              name="investigation.min"
              control={control}
              numberFormatProps={{
                prefix: '€'
              }}
              required
            />
            <MsiFormNumber
              label="Max"
              name="investigation.max"
              control={control}
              numberFormatProps={{
                prefix: '€'
              }}
              required
            />
          </>
        }
      </Box>

      <Divider sx={{ margin: '30px 0 20px' }} textAlign="center">
        <Typography component="label"
          sx={{
            background: theme => theme.palette.primary.main,
            padding: '0.3em'
          }}
        >
          DETTAGLI
        </Typography>
      </Divider>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        columnGap: '20px',
        rowGap: '25px'
      }}
      >
        <MsiFormTextArea
          maxRows={15}
          label="Dettagli Assicurazione"
          name="details.insurance"
          control={control}
          sx={{ gridColumn: 'span 4' }}
        />
        <MsiFormTextArea
          maxRows={15}
          label="Estinzione Anticipata"
          name="details.early_termination_penalty"
          control={control}
          sx={{ gridColumn: 'span 2' }}
        />
        <MsiFormTextArea
          maxRows={15}
          label="Garanzie Richieste"
          name="details.requested_guarantee"
          control={control}
          sx={{ gridColumn: 'span 2' }}
        />
        <MsiFormTextArea
          maxRows={15}
          label="Istante Erogazione"
          name="details.time_of_disbursement"
          control={control}
          sx={{ gridColumn: 'span 2' }}
        />
        <MsiFormTextArea
          maxRows={15}
          label="Calcolo Tasso"
          name="details.rate_calculation"
          control={control}
          sx={{ gridColumn: 'span 2' }}
        />
        <MsiFormTextArea
          maxRows={15}
          label="Note"
          name="details.notes"
          control={control}
          sx={{ gridColumn: 'span 4' }}

        />
      </Box>
      {/* <pre>{JSON.stringify(getFieldState('loan_type'), null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </MsiModal>
  )
}
