
import { all, call, put, takeLatest } from 'redux-saga/effects'

import * as services from '@/services/rate'
import { actions } from './slice'

function* rateItemCreateRequest ({ payload }:ReturnType<typeof actions.RATE_ITEM_CREATE_REQUEST>) {
  try {
    yield call(services.createRate, payload.data)
    yield put(actions.RATE_ITEM_CREATE_SUCCESS())
  } catch (error) {
    yield put(actions.RATE_ITEM_CREATE_FAILURE((error as Error).message))
  }
}
function* rateItemUpdateRequest ({ payload }:ReturnType<typeof actions.RATE_ITEM_UPDATE_REQUEST>) {
  try {
    yield call(services.updateRate, payload.id, payload.data)
    yield put(actions.RATE_ITEM_UPDATE_SUCCESS())
  } catch (error) {
    yield put(actions.RATE_ITEM_UPDATE_FAILURE((error as Error).message))
  }
}
function* rateItemDeleteRequest ({ payload }:ReturnType<typeof actions.RATE_ITEM_DELETE_REQUEST>) {
  try {
    yield call(services.deleteRate, payload.id)
    yield put(actions.RATE_ITEM_DELETE_SUCCESS())
  } catch (error) {
    yield put(actions.RATE_ITEM_DELETE_FAILURE((error as Error).message))
  }
}

export function* ratesSagas () {
  yield all([
    takeLatest(actions.RATE_ITEM_CREATE_REQUEST.type, rateItemCreateRequest),
    takeLatest(actions.RATE_ITEM_UPDATE_REQUEST.type, rateItemUpdateRequest),
    takeLatest(actions.RATE_ITEM_DELETE_REQUEST.type, rateItemDeleteRequest)
  ])
}
