import type { ComponentProps } from 'react'
import { Dispatch } from 'react'
import type { SelectChangeEvent } from '@mui/material'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

type Props = Omit<ComponentProps<typeof Select>, 'onChange'> & {
  onChange: Dispatch<string>
  options: { label: string, value: string }[]
}

export function MsiSelect ({ onChange, options, ...props }:Props) {
  function handleChange (e:SelectChangeEvent<unknown>) {
    onChange(e.target.value as string)
  }

  return (
    <FormControl sx={props.sx}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        onChange={handleChange}
        variant="outlined"
        defaultValue={''}
        {...props}
      >
        <MenuItem value={''}>&nbsp;</MenuItem>
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>

  )
}
