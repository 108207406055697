import { BrowserRouter } from 'react-router-dom'
import { Box } from '@mui/material'

import { MsiModals } from '@/components/modals/MsiModals'
import { MsiRoutes } from '@/routes/MsiRoutes'
import { MsiHeader } from './MsiHeader'
import { MsiToast } from './MsiToast'

export function MsiLayout () {
  return (

    <BrowserRouter>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        bgcolor: 'background.default',
        color: 'text.primary'
      }}
      >
        <MsiHeader />
        <Box component="main" sx={{
          flex: 1,
          padding: '20px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
        >
          <MsiRoutes />
        </Box>
        <MsiModals />
        <MsiToast />
      </Box>
    </BrowserRouter>
  )
}
