/* eslint-disable newline-per-chained-call */
import * as yup from 'yup'

export const schema = yup.object().shape({
  loan_id: yup.string().required(),
  spread: yup.number().nullable().min(0).required('required'),
  ltv_min: yup.number().nullable().min(0).required('required'),
  ltv_max: yup.number().nullable().min(yup.ref('ltv_min')).required('required'),
  durations: yup.lazy((item, obj) => {
    if (!obj?.parent?.durations) return yup.array().nullable()

    const [min, max] = obj.parent.durations

    return yup.array()
      .test(
        'test-min-required',
        'min is required',
        () => { return !!min }
      )
      .test(
        'test-max-required',
        'max is required',
        () => { return !!max }
      )
      .test(
        'test-max',
        `max should be larger or equal to ${min}`,
        () => { return max >= min }
      )
      .test(
        'test-min-remainder',
        'min should be multiple of 5',
        () => { return !(min % 5) }
      )
      .test(
        'test-max-remainder',
        'max should be multiple of 5',
        () => { return !(max % 5) }
      )
  })
})
