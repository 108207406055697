import type { ComponentProps, HTMLInputTypeAttribute } from 'react'
import type { UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { TextField } from '@mui/material'

type x = UseControllerProps<any>
type y = ComponentProps<typeof TextField>

type Props = x & y & {
  label: string,
  type?: HTMLInputTypeAttribute
  readOnly?: boolean
}

export function MsiFormInput (props:Props) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error, isTouched }
  } = useController(props)

  return (
    <TextField
      onChange={onChange}
      onBlur={onBlur}
      value={value || ''}
      inputRef={ref}
      type={props.type || 'text'}
      variant="outlined"
      helperText={(value || isTouched) && error?.message}
      error={(value || isTouched) && !!error}
      {...props}
    />
  )
}
