import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import type { } from '@mui/x-data-grid/themeAugmentation'

const baseTheme = {
  palette: {
    primary: {
      main: '#f8cf03'
    },
    secondary: {
      main: blueGrey[500]
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#d32f2f',
          zIndex: 2
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footerContainer': {
            background: '#555'
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'space-between'
          },
          '& div.MuiDataGrid-columnHeaderTitle': {
            textTransform: 'uppercase'
          },
          '& .MuiDataGrid-cell.first-capital *::first-letter': {
            textTransform: 'uppercase'
          },
          '& .MuiDataGrid-cell.numeric >div': {
            width: '3em',
            textAlign: 'right'
          },
          '& .MuiDataGrid-cell.breakSpace *': {
            whiteSpace: 'normal'
          },
          '& .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable):focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell--editable': {
            cursor: 'pointer',
            background: '#3a3a3a'
          },
          // '& .MuiTablePagination-spacer': {
          //   display: 'none'
          // },
          // '& .MuiTablePagination-root': {
          //   flex: 1
          // },
          '& .MuiToolbar-root': {
            justifyContent: 'space-between'
          }
        }
      }
    }
  }
}

const darkTheme = createTheme(deepmerge(baseTheme, {
  palette: {
    mode: 'dark',
    background: {
      default: '#303030'
    },
    secondary: {
      main: blueGrey[300]
    }
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: '#303030'
        }
      }
    }
  }
}))

const lightTheme = createTheme(baseTheme, {
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: '#fff'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: '#f8cf0366',
            '&:hover': {
              backgroundColor: '#f8cf0366'
            }
          }
        }
      }
    }
  }
})

export { darkTheme, lightTheme }

export type Theme = typeof baseTheme
