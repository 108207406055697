import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import { MsiLoanSearch } from '@/components/loanSearch/MsiLoanSearch'
import { MsiNavRates } from '@/components/MsiNavRates'
import { MsiLoansTable } from '@/components/tables/MsiLoansTable'
import { useAppDispatch, useLoans } from '@/hooks'
import { actions as modalActions } from '@/redux/modal/slice'
import type { Loan, LoanOverview, LoanOverviewFilters } from '@/types'
import { filterAndSortyArrayByObject } from '@/utils'

export function MsiLoans () {
  const dispatch = useAppDispatch()
  const { bankId } = useParams() as {bankId: string}
  const { data } = useLoans(bankId)
  const [filteredData, setFilteredData] = useState([] as LoanOverview[])
  const [filters, setFilters] = useState({} as LoanOverviewFilters)

  useEffect(() => {
    if (data?.loans) applyFilters()
  }, [data?.loans, filters])

  if (!data) {
    return <></>
  }

  function createLoan () {
    dispatch(modalActions.MODAL_ITEM_SET({
      action: 'create',
      type: 'loan',
      item: { bank_id: bankId } as Loan
    }))
  }

  function applyFilters () {
    const filtered = filterAndSortyArrayByObject(data!.loans, filters)
    setFilteredData(filtered)
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 2 }}>
        <Box flex={1}>
          <MsiNavRates />
        </Box>
        <div>Bank: <Typography component="label"
          variant="h6"
          sx={{
            marginLeft: 1,
            color: theme => theme.palette.primary.main
          }}
                   >
          {data.bank_name}
        </Typography>
        </div>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={createLoan}>
            Create Loan
          </Button>
        </Box>
      </Box>
      <MsiLoanSearch onChange={setFilters} />
      <MsiLoansTable data={filteredData} />
    </>
  )
}
