/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { all, call, put, takeLatest } from 'redux-saga/effects'

import * as services from '@/services/loanVariant'
import { loanVariantModelToRequestBody } from '@/utils'
import { actions } from './slice'

function* loanVariantItemCreateRequest ({ payload }:ReturnType<typeof actions.LOANVARIANT_ITEM_CREATE_REQUEST>) {
  try {
    // @ts-ignore
    yield call(services.createLoanVariant, payload.loanId, payload.data)
    yield put(actions.LOANVARIANT_ITEM_CREATE_SUCCESS())
  } catch (error) {
    yield put(actions.LOANVARIANT_ITEM_CREATE_FAILURE((error as Error).message))
  }
}
function* loanVariantItemUpdateRequest ({ payload }:ReturnType<typeof actions.LOANVARIANT_ITEM_UPDATE_REQUEST>) {
  try {
    // @ts-ignore
    yield call(services.updateLoanVariant, payload.loanId, payload.id, payload.data)
    yield put(actions.LOANVARIANT_ITEM_UPDATE_SUCCESS())
  } catch (error) {
    yield put(actions.LOANVARIANT_ITEM_UPDATE_FAILURE((error as Error).message))
  }
}
function* loanVariantItemDeleteRequest ({ payload }:ReturnType<typeof actions.LOANVARIANT_ITEM_DELETE_REQUEST>) {
  try {
    yield call(services.deleteLoanVariant, payload.loanId, payload.id)
    yield put(actions.LOANVARIANT_ITEM_DELETE_SUCCESS())
  } catch (error) {
    yield put(actions.LOANVARIANT_ITEM_DELETE_FAILURE((error as Error).message))
  }
}
export function* loanVariantItemsCloneRequest ({ payload }:ReturnType<typeof actions.LOANVARIANT_ITEMS_CLONE_REQUEST>) {
  try {
    for (const loanVariant of payload.loanVariants) {
      const { id, loan_id, ...data } = loanVariantModelToRequestBody(loanVariant)
      // @ts-ignore
      yield call(services.createLoanVariant, payload.loanId, data)
    }
    yield put(actions.LOANVARIANT_ITEMS_CLONE_SUCCESS())
  } catch (error) {
    yield put(actions.LOANVARIANT_ITEMS_CLONE_FAILURE((error as Error).message))
  }
}

export function* loanVariantsSagas () {
  yield all([
    takeLatest(actions.LOANVARIANT_ITEM_CREATE_REQUEST.type, loanVariantItemCreateRequest),
    takeLatest(actions.LOANVARIANT_ITEM_UPDATE_REQUEST.type, loanVariantItemUpdateRequest),
    takeLatest(actions.LOANVARIANT_ITEM_DELETE_REQUEST.type, loanVariantItemDeleteRequest),
    takeLatest(actions.LOANVARIANT_ITEMS_CLONE_REQUEST.type, loanVariantItemsCloneRequest)
  ])
}
