import { createSlice } from '@reduxjs/toolkit'

import type { RateBody } from '@/types'

export const { actions, reducer } = createSlice({
  name: 'rates',
  initialState: { loading: false, revalidate: false, success: false, error: '' },
  reducers: {
    RATE_ITEM_CREATE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '' }
      },
      prepare: (data:RateBody) => ({ payload: { data } })
    },
    RATE_ITEM_CREATE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    RATE_ITEM_CREATE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    RATE_ITEM_UPDATE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '' }
      },
      prepare: ({ id, ...data }:RateBody) => ({ payload: { id, data } })
    },
    RATE_ITEM_UPDATE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    RATE_ITEM_UPDATE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    RATE_ITEM_DELETE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '' }
      },
      prepare: (id:string) => ({ payload: { id } })
    },
    RATE_ITEM_DELETE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    RATE_ITEM_DELETE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    RESET: () => {
      return { loading: false, revalidate: false, success: false, error: '' }
    }

  }
})
