export * from './adapters'
export * from './filter'
export * from './format'
export * from './rates'

export const logger = (alsoProd = false) => (...arg:any[]) => {
  if (process.env.NODE_ENV === 'development' || alsoProd) {
    console.log(arg)
  }
}

export function debounce (fn:(...args:any[])=>any, delay:number) {
  let timeout:NodeJS.Timeout
  let _args:any[]

  function debounced (...args:any[]) {
    _args = args
    clearTimeout(timeout)
    timeout = setTimeout(debounced.flush, delay)
  }
  debounced.cancel = () => {
    clearTimeout(timeout)
  }
  debounced.flush = () => {
    if (!timeout) return
    debounced.cancel()
    fn(..._args)
  }

  return debounced
}

export function removeNullFields<T> (obj:T) {
  return Object.entries(obj)
    .reduce((acc, [key, val]) => {
      if (val === null) {
        return acc
      }
      if (typeof val === 'object' && !Array.isArray(val) && Object.values(val).length) {
        acc[key as keyof T] = removeNullFields(val)

        return acc
      }
      acc[key as keyof T] = val

      return acc
    }, {} as T)
}
