import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'

import { rateTypeOptions } from '@/components/forms/consts'
import { MsiFormInput } from '@/components/forms/controls/MsiFormInput'
import { MsiFormNumber } from '@/components/forms/controls/MsiFormNumber'
import { MsiFormSelect } from '@/components/forms/controls/MsiFormSelect'
import { MsiModal } from '@/components/modals/MsiModal'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { actions as modalActions } from '@/redux/modal/slice'
import { actions as ratesActions } from '@/redux/rates/slice'
import type { Rate } from '@/types'
import { rateModelToRequestBody } from '@/utils'
import { schema } from './schema'

export function MsiFormRate () {
  const dispatch = useAppDispatch()
  const { action, type, item } = useAppSelector(state => state.modal)
  const { control, getValues, formState: { isValid } } = useForm({
    mode: 'all',
    defaultValues: item as Rate,
    resolver: yupResolver(schema)
  })

  const isCrawlerManaged = (item as Rate).details?.crawler_managed

  function dismiss () {
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  function confirm () {
    const item = rateModelToRequestBody(getValues())
    if (action === 'edit') {
      dispatch(ratesActions.RATE_ITEM_UPDATE_REQUEST(item))
    }
    if (action === 'create') {
      dispatch(ratesActions.RATE_ITEM_CREATE_REQUEST(item))
    }
    dispatch(modalActions.MODAL_ITEM_RESET())
  }

  return (
    <MsiModal
      primaryBtnProps={{ children: 'confirm', onClick: confirm, disabled: !isValid }}
      secondaryBtnProps={{ children: 'cancel', onClick: dismiss }}
      title={`${action} ${type}`}
    >
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridAutoRows: '62px',
        columnGap: '20px',
        rowGap: '25px'
      }}
      >
        <MsiFormSelect
          label="Tasso"
          name="rate_type"
          control={control}
          options={rateTypeOptions}
          readOnly={isCrawlerManaged}
        />
        <MsiFormInput
          label="Nome Indice"
          name="rate_name"
          control={control}
          inputProps={{
            readOnly: isCrawlerManaged
          }}
        />
        <MsiFormNumber
          label="Valore"
          name="rate_value"
          control={control}
          numberFormatProps={{
            suffix: '%'
          }}
        />
      </Box>
    </MsiModal>
  )
}
