import { createSlice } from '@reduxjs/toolkit'

import type { BankBody } from '@/types'

export const { actions, reducer } = createSlice({
  name: 'banks',
  initialState: { loading: false, revalidate: false, success: false, error: '', latestBankId: '' },
  reducers: {
    BANK_ITEM_CREATE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '', latestBankId: '' }
      },
      prepare: (data:BankBody) => ({ payload: { data } })
    },
    BANK_ITEM_CREATE_SUCCESS (state, action) {
      return { ...state, loading: false, revalidate: true, success: true, latestBankId: action.payload }
    },
    BANK_ITEM_CREATE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    BANK_ITEM_UPDATE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '', latestBankId: '' }
      },
      prepare: ({ id, ...data }:BankBody) => ({ payload: { id, data } })
    },
    BANK_ITEM_UPDATE_SUCCESS (state, action) {
      return { ...state, loading: false, revalidate: true, success: true, latestBankId: action.payload }
    },
    BANK_ITEM_UPDATE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    BANK_ITEM_DELETE_REQUEST: {
      reducer: () => {
        return { loading: true, revalidate: false, success: false, error: '', latestBankId: '' }
      },
      prepare: (id:string) => ({ payload: { id } })
    },
    BANK_ITEM_DELETE_SUCCESS (state) {
      return { ...state, loading: false, revalidate: true, success: true }
    },
    BANK_ITEM_DELETE_FAILURE (state, action) {
      return { ...state, loading: false, error: action.payload }
    },

    RESET: () => {
      return { loading: false, revalidate: false, success: false, error: '', latestBankId: '' }
    }

  }
})
